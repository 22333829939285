<template>
  <div>
    <div id="view-header__teleport"></div>
    <div v-if="!notColoredHeader.routes.includes($route.name)">
      <div
        :class="{
    'view-header--protocol-details' : !notColoredHeader.routes.includes($route.name)
     }"
        class="view-header">
        <div class="view-header__header">
          <div class="tmp-col">
            <Btn
              v-if="!task.date_realisation
              && task?.type?.uid !== 'NOTATION'
              && helperService.userHasPermission('essaievenement_oedit')
              "
              text="Réaliser la tâche expérimentale"
              :hollow="!notColoredHeader.routes.includes($route.name)"
              :color="!notColoredHeader.routes.includes($route.name) ? 'white': 'primary'"
              icon="check"
              @click="modal.realizeEvent = true"
            />
          </div>
          <div class="tmp-col">
            <Btn
              v-if="helperService.userHasPermission('essaievenement_oremove') && task?.type?.uid !== 'NOTATION'"
              text="Supprimer la tâche expérimentale"
              :hollow="!notColoredHeader.routes.includes($route.name)"
              :color="!notColoredHeader.routes.includes($route.name) ? 'white': 'primary'"
              icon="trash-alt"
              @click="modal.deleteEvent = true"
            />

            <Btn
              v-if="helperService.userHasPermission('essaievenement_oremove') && task?.type?.uid === 'NOTATION' && !notation?.est_herite && isEditable"
              text="Supprimer la tâche expérimentale"
              :hollow="!notColoredHeader.routes.includes($route.name)"
              :color="!notColoredHeader.routes.includes($route.name) ? 'white': 'primary'"
              icon="trash-alt"
              @click="modal.deleteEvent = true"
            />

          </div>
        </div>
        <div class="view-header__footer">
          <Btn
            v-if="$route.params.id"
            class="back-btn"
            round
            grow
            icon="arrow-left"
            :to="{
          name: 'essaiCalendar',
          params: {
            id: $route.params.id
          }
        }"
          />
          <h1 class="page-title">
            <template v-if="!notColoredHeader.routes.includes($route.name)">
              <template v-if="!task?.designation">
                {{ task?.type?.designation }}
              </template>
              <template v-else>
                {{ task?.type?.designation }} - {{ task?.designation }}
              </template>
            </template>
            <template v-else>
              <template v-if="!task?.designation">
                {{ task?.type?.designation }} - {{ pageTitle }}
              </template>
              <template v-else>
                {{ task?.type?.designation }} - {{ task?.designation }} - {{ pageTitle }}
              </template>
            </template>
            <div class="page-subtitle">Essai :
              {{ essai?.numero ? `${essai?.numero} -` : '' }} {{ essai?.nom}}
            </div>
          </h1>
        </div>
      </div>
    </div>
  </div>

  <div class="view-body">
    <Section class="section section--no-padding">
      <TabsMaterial
        v-if="!notColoredHeader.routes.includes($route.name)"
        :tabs="tabs"
      />

      <router-view
        :key="componentKey"
        v-if="Object.keys(task).length"
        :task="task"
        :essai="essai"
        :pageTitle="pageTitle"
        :isEditable="isEditable"
      >
      </router-view>
    </Section>
  </div>

  <NavigationDropdownEssai />

  <!-- Modals realizeEvent -->
  <Modal
    title="Déclarer la réalisation de la tâche expérimentale&nbsp;?"
    :active="modal.realizeEvent"
    :data="modalData"
    @modal-close="modal.realizeEvent = false"
  >
    <template v-slot:modal-body>
      <Input
        v-model="realizationDate"
        required
        id="date_realisation"
        label="Date de réalisation"
        type="date"
        :options="{
          max: new Date().toISOString().split('T')[0]
        }"
      />
    </template>
    <template v-slot:modal-footer>
      <Btn text="Annuler" @click="modal.realizeEvent = false" />
      <Btn
        text="Déclarer"
        @click="realizeEvent()"
        icon="check"
        color="primary"
      />
    </template>
  </Modal>

  <!-- Modals modalDelete-->
  <Modal
    title="Supprimer la tâche expérimentale&nbsp;?"
    :active="modal.deleteEvent"
    :data="modalData"
    @modal-close="modal.deleteEvent = false"
  >
    <template v-slot:modal-body>
      <p>
        Voulez vous vraiment supprimer la tâche expérimentale ?
      </p>
    </template>
    <template v-slot:modal-footer>
      <Btn text="Annuler" @click="modal.deleteEvent = false" />
      <Btn
        text="Supprimer"
        @click="deleteEvent()"
        icon="trash-alt"
        color="primary"
      />
    </template>
  </Modal>
</template>

<script>
import Section from '@/components/layout/Section.vue'
import Btn from '@/components/base/Btn.vue'
import TabsMaterial from '@/components/layout/TabsMaterial.vue'
import NavigationDropdownEssai from '@/views/componentsViews/navigationDropdown/NavigationDropdownEssai.vue'
import Modal from '@/components/layout/Modal.vue'
import Input from '@/components/form/Input.vue'

export default {
  name: 'ExperimentalTaskAddEditView',

  components: {
    Input,
    Modal,
    NavigationDropdownEssai,
    Btn,
    Section,
    TabsMaterial,
  },

  props: {
    pageTitle: {
      type: String,
    },
  },

  data() {
    return {
      notColoredHeader: {
        routes: [
          'essaiExperimentalTaskVariableStudiedSettingsAdd',
          'essaiExperimentalTaskVariableStudiedEntryLevelAdd',
          'essaiExperimentalTaskVariableStudiedSettingsEdit',
          'essaiExperimentalTaskVariableStudiedEntryLevelEdit',
        ],
      },
      task: {},
      notation: {},
      essai: {},
      modal: {
        deleteEvent: false,
        realizeEvent: false,
      },
      modalData: {},
      realizationDate: new Date().toISOString().split('T')[0],
      componentKey: 0,
      isEditable: true,
    }
  },

  computed: {
    tabs() {
      return [
        {
          urlName: 'essaiExperimentalTaskGeneralInformationEdit', text: 'Informations générales',
        },
        ...(this.task?.type?.uid === 'PRELEVEMENT'
          ? [{ urlName: 'essaiExperimentalTaskOrganEdit', text: 'Organe' }]
          : []
        ), // Prélevement
        ...(this.task?.type?.uid === 'CONTAMINATION'
          ? [{ urlName: 'essaiExperimentalTaskArtificialContaminationEdit', text: 'Produit contaminant' }]
          : []
        ), // Contamination artificielle
        ...(this.task?.type?.uid === 'TRAITEMENTFERTI'
          ? [
            { urlName: 'essaiExperimentalTaskFichePeseesFertiEdit', text: 'Fiche de pesées' },
            { urlName: 'essaiExperimentalTaskConditionsTraitementEdit', text: 'Conditions de traitement' },
          ]
          : []
        ), // Traitement fertilisation
        ...(this.task?.type?.uid === 'TRAITEMENTPHYTO'
          ? [
            { urlName: 'essaiExperimentalTaskFichePeseesPhytoEdit', text: 'Fiche de pesées' },
            { urlName: 'essaiExperimentalTaskConditionsTraitementEdit', text: 'Conditions de traitement' },
            { urlName: 'essaiExperimentalTaskVolumesNonEpandusEdit', text: 'Volumes non épandus' },
          ]
          : []
        ), // Traitement phyto
        ...(this.task?.type?.uid === 'RECOLTE'
          ? [
            { urlName: 'essaiExperimentalTaskConditionsRecolteEdit', text: 'Conditions de récolte' },
          ]
          : []
        ), // Conditions de récolte
        ...(this.task?.type?.uid === 'SEMIS'
          ? [
            { urlName: 'essaiExperimentalTaskFichePeseesSemisEdit', text: 'Fiche de pesées' },
          ]
          : []
        ), // Semis
        ...(this.task?.type?.uid === 'DESTRUCTION'
          ? [
            { urlName: 'essaiExperimentalTaskDestructionVegetationEdit', text: 'Destruction en végétation' },
            { urlName: 'essaiExperimentalTaskDestructionRecolteEdit', text: 'Destruction en récolte' },
            { urlName: 'essaiExperimentalTaskDestructionProofEdit', text: 'Preuves de destruction' },
          ]
          : []
        ), // Destruction
        ...(this.task?.type?.uid === 'NOTATION'
          ? [
            { urlName: 'essaiExperimentalTaskParameterEdit', text: 'Paramètres' },
            { urlName: 'essaiExperimentalTaskVariableStudiedListEdit', text: 'Variables étudiées' },
            { urlName: 'essaiExperimentalTaskVariableCalculatedEdit', text: 'Variables calculées' },
            {
              urlName: 'essaiExperimentalTaskNotationStateEdit',
              params: {
                id: this.$route.params.id,
                tid: this.$route.params.tid,
              },
              text: 'Etat',
            },
          ]
          : []
        ), // Notation
      ]
    },
  },

  mounted() {
    this.getEssai()
    this.getExperimentalTask()
  },

  methods: {
    openModal(modal, data) {
      this.modalData = data
      this.modal[modal] = true
    },

    getEssai() {
      this.fetchService.get(`essai/${this.$route.params.id}`).then(
        (response) => {
          this.essai = response.data
        },
      )
    },

    getExperimentalTask() {
      this.fetchService.get(`essai/${this.$route.params.id}/evenement/${this.$route.params.tid}`)
        .then((response) => {
          this.task = response.data

          if (this.task.type.uid === 'NOTATION') {
            this.loadNotation()
          }
        })
    },

    realizeEvent() {
      const dateRealization = Date.parse(this.realizationDate)

      if (this.realizationDate === '') {
        this.emitter.emit('alert', {
          type: 'error',
          content: 'La date de réalisation de la tâche expérimentale n\'a pas été renseignée.',
        })
      } else if (this.realizationDate !== '' && dateRealization > Date.now()) {
        this.emitter.emit('alert', {
          type: 'error',
          content: 'La date de réalisation de la tâche expérimentale n\'est pas valide.',
        })
      } else {
        this.emitter.emit('open-loader')
        this.fetchService.put(`essai/${this.$route.params.id}/evenement/${this.$route.params.tid}`, {
          date_realisation: this.helperService.formatDateForApi(this.realizationDate),
        })
          .then(() => {
            this.emitter.emit('alert', {
              type: 'success',
              content: 'La date de réalisation de la tâche expérimentale a bien été modifiée.',
            })

            this.getExperimentalTask()
            this.emitter.emit('close-loader')
          })

        this.modal.realizeEvent = false
      }
    },

    deleteEvent() {
      this.emitter.emit('open-loader')

      this.fetchService
        .delete(`essai/${this.$route.params.id}/evenement/${this.$route.params.tid}`)
        .then(
          () => {
            this.emitter.emit('alert', {
              type: 'success',
              content: `La tâche expérimentale ${this.$route.params.tid} a bien été supprimé.`,
            })

            this.$router.push({
              name: 'essaiCalendar',
              params: {
                id: this.$route.params.id,
              },
            })
          },
          (responseError) => {
            this.emitter.emit('alert', {
              type: 'error',
              content: responseError.data,
            })
          },
        )

      this.emitter.emit('close-loader')
      this.modal.deleteEvent = false
    },
    loadNotation() {
      this.fetchService
        .get(
          `essai/${this.$route.params.id}/evenement/${this.$route.params.tid}/fiche/notation`,
        )
        .then((reponseNotation) => {
          const notationResponse = reponseNotation.data
          this.notation = notationResponse.pop()
          this.fetchService
            .get(
              `essai/${this.$route.params.id}/evenement/${this.$route.params.tid}/fiche/notation/${this.notation.id}/reservation?sort=id.DESC&limit=1`,
            )
            .then((reponseReservation) => {
              let reservation = reponseReservation.data
              reservation = reservation.pop()
              this.setEditable(reservation?.etat?.uid)
            })
        })
    },
    setEditable(step) {
      if (step) {
        this.isEditable = ['ENPREPARATION'].includes(step)
      } else {
        this.isEditable = true
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.view-header__header {
  display: flex;
  gap: $gutter-half;
}
</style>
